import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors.js";
import Page from "../templates/page";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    alt
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      metadata {
        lqip
        dimensions {
          aspectRatio
          width
          height
        }
      }
    }
  }

  query FrontpageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
    allSanityPartners {
      edges {
        node {
          id
          partners {
            _key
            name
            partnerDescription
            partnerLogo {
              alt
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
  
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  const partners = data?.allSanityPartners?.edges[0]?.node?.partners || false

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <Page data={data} indexPage={true} />;
};

export default IndexPage;
